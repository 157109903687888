import type { HotspotModalProps } from '@integration-layer/components/Hotspot/HotspotModal.props'

export const useHotspotModal = () => {
  const hotspotModalState = useState<HotspotModalProps | undefined>(
    'hotspot-modal-props'
  )

  const setHotspotModalState = (props: HotspotModalProps | undefined) => {
    hotspotModalState.value = props
  }

  return {
    hotspotModalState,
    setHotspotModalState,
  }
}
